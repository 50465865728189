import { defineStore } from "pinia";

import { z } from "zod";

import { schema } from "~/.nuxt/contracts/src/routes/dashboard/notifications";

export const putNotificationBodySchema =
  schema["/dashboard/notifications/:action/:nanoid"].put.body;
export const putNotificationParamsSchema =
  schema["/dashboard/notifications/:action/:nanoid"].put.params;
export const notificationsResponseSchema =
  schema["/dashboard/notifications"].get.response[200].shape.data;
export const notificationSchema = notificationsResponseSchema.element;
export const getNotificationsQueryStringSchema =
  schema["/dashboard/notifications"].get.querystring;

export type NotificationsRole = z.infer<
  typeof notificationsResponseSchema.element.shape.role
>;

export const useNotificationsStore = defineStore("notifications", {
  state: (): {
    loading: boolean;
    notifications: z.infer<typeof notificationsResponseSchema>;
  } => ({
    loading: false,
    notifications: [],
  }),
  actions: {
    async getNotifications(
      payload: z.infer<typeof getNotificationsQueryStringSchema>
    ) {
      const response = await this.$client["/dashboard/notifications"].get({
        query: payload,
      });
      if (!response.ok) {
        throw new Error(response.statusText);
      }

      const responseJson = await response.json();
      this.notifications = responseJson.data;
    },
    async updateStatus(
      payload: z.infer<typeof putNotificationBodySchema> &
        z.infer<typeof putNotificationParamsSchema> & { index: number }
    ) {
      const response = await this.$client[
        "/dashboard/notifications/{action}/{nanoid}"
      ].put({
        json: {
          status: payload.status,
        },
        params: {
          action: payload.action,
          nanoid: payload.nanoid,
        },
      });
      if (!response.ok) {
        throw new Error(response.statusText);
      }

      this.notifications.splice(payload.index, 1);
    },
  },
});
