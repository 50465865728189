import { z } from 'zod'
import { inviteNanoid } from '../../brands.js'
import { invites } from '../../codegen/zod/rise/invites.js'
import { recaptcha } from '../../formats.js'
import { defaultErrorResponses, defaultResponse } from '../../response.js'
import type { Schema } from '../../types.js'

const notificationsResponse = defaultResponse.extend({
	data: z.array(
		z.object({
			nanoid: z.string(),
			action: z.string().min(1),
			role: invites.shape.role.nullable(),
			inviter_name: z.string().min(1),
			company: z.object({
				name: z.string().min(1),
				avatar: z.string(),
			}),
		}),
	),
})

const putNotificationBody = z.object({
	status: z.enum(['accepted', 'rejected']),
})
export const schema = {
	'/dashboard/notifications': {
		get: {
			tags: ['Dashboard'],
			security: [{ bearerAuth: [] }],
			summary: 'List notifications',
			querystring: z.object({
				action: z.string().min(1),
			}),
			response: {
				200: notificationsResponse,
				...defaultErrorResponses,
			},
		},
	},
	'/dashboard/notifications/:action/:nanoid': {
		put: {
			tags: ['Dashboard'],
			security: [{ bearerAuth: [] }],
			headers: recaptcha,
			summary: 'Update notification',
			params: z.object({
				action: z.string().min(1).trim(),
				nanoid: inviteNanoid,
			}),
			body: putNotificationBody,
			response: {
				200: defaultResponse.extend({ data: putNotificationBody }),
				...defaultErrorResponses,
			},
		},
	},
} as const satisfies Schema
